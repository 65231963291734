import React from "react";
import { NavLink } from "react-router-dom";
import "../Components/SideMenu.css";
export function SideMenu(props) {
  return (
    <>
      <div className="MenuContainer">
        <h2>View</h2>
        <NavLink onClick={props.onClick} exact="true" to="/Stats">
          <div>User Stats</div>
        </NavLink>
        <h2>Edit</h2>
        <NavLink onClick={props.onClick} exact="true" to="/Questions">
          <div>Questions</div>
        </NavLink>
        <NavLink onClick={props.onClick} exact="true" to="/CV">
          <div>Constant Values (CVs)</div>
        </NavLink>
        <NavLink onClick={props.onClick} exact="true" to="/Contracts">
          <div>Smart Contracts</div>
        </NavLink>
        <h2>Manage</h2>
        <NavLink onClick={props.onClick} exact="true" to="/Whitelabels">
          <div>Whitelables</div>
        </NavLink>
        {/* <NavLink onClick={props.signOut}>Log out</NavLink> */}
        {/* <NavLink onClick={props.onClick} exact="true" to="/Accounts">
          <div>Admin Accounts</div>
        </NavLink> */}
      </div>
    </>
  );
}
