import React from "react";

export function Welcome(props) {
  console.log("props.user :>> ", props.user);
  return (
    <>
      <h1>Welcome</h1>
      <p>You are currently loggged in as {props.user.attributes.given_name}.</p>
    </>
  );
}
