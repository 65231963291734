import React, { useState } from "react";
import DivButton from "./Inputs/DivButton";
import "../Components/WhiteLabelItem.css";

export function WhiteLabelItem(props) {
  const item = props.WhiteLabelObj;
  const newItem = {
    CompanyName: "New WhiteLabel",
    Logos: {
      favicon: "",
      pdf: "",
      website: "",
    },
    Colors: {
      topBarBG: "#07115A",
      topBarText: "#FFFFF",
      buttonBG: "#07115A",
      buttonHoverBG: "#4596CC",
      buttonText: "#FFFFF",
      text: "#4596CC",
    },
    Domain: "",
  };

  const [isNew, setNew] = useState(
    item === "New WhiteLabel" || item === undefined
  );
  const [WLO, setWLO] = useState(isNew === true ? newItem : item);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const [domainText, setDomainText] = useState(isNew ? "" : item.Domain);
  const [mainLogo, setMainLogo] = useState(isNew ? "" : item.Logos.website);
  const [pdfLogo, setPdfLogo] = useState(isNew ? "" : item.Logos.pdf);
  const [favIcon, setFavicon] = useState(isNew ? "" : item.Logos.favIcon);
  const [companyName, setCompanyName] = useState(isNew ? "" : item.CompanyName);

  const [errorMessage, setMessage] = useState("");

  function Validate() {
    if (companyName.trim() === "New WhiteLabel" || companyName.trim() === "") {
      setMessage("Company Name Cannot be blank");
      return false;
    }

    if (mainLogo.trim() === "" || isValidUrl(domainText) === false) {
      setMessage("Logo URL not valid");

      return false;
    }
    if (pdfLogo.trim() === "" || isValidUrl(domainText) === false) {
      setMessage("Logo URL not valid");
      return false;
    }
    if (favIcon.trim() === "" || isValidUrl(domainText) === false) {
      setMessage("Logo URL not valid");
      return false;
    }
    if (domainText.trim() === "" || isValidUrl(domainText) === false) {
      setMessage("Domain URL not valid");
      return false;
    }
    setMessage("");

    const tempItem = { ...WLO };
    tempItem.CompanyName = companyName;
    tempItem.Domain = domainText;
    tempItem.Logos.favIcon = favIcon;
    tempItem.Logos.pdf = pdfLogo;
    tempItem.Logos.website = mainLogo;

    setWLO(tempItem);
    return true;
  }

  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };

  function handleSubmit() {
    if (Validate()) {
      setOpen(!open);
      setEdit(!edit);
      props.handleSubmit(WLO);
    }
  }

  function onEditText(e) {
    const name = e.target.name;
    const val = e.target.value;

    // console.log("name :>> ", name);
    // console.log("val :>> ", val);
    if (name === "Domain") {
      setDomainText(val);
    }
    if (name === "Main") {
      setMainLogo(val);
    }
    if (name === "PDF") {
      setPdfLogo(val);
    }
    if (name === "favIcon") {
      setFavicon(val);
    }
    if (name === "CompanyName") {
      setCompanyName(val);
    }
  }

  function Logo(props) {
    const editMode = props.edit === undefined || props.edit === false;
    return (
      <div className={editMode ? "logoContainer" : "logoCap logoContainer "}>
        <div className={editMode ? "logoCap" : "dataLabel"}>
          <span>Main Logo</span>
          <div
            className="logoItem"
            style={{ backgroundColor: WLO.Colors.topBarBG }}
          >
            <img src={mainLogo} alt="Main Logo Image" />
          </div>
          {editMode === false ? (
            <textarea
              name="Main"
              value={mainLogo}
              onChange={(e) => onEditText(e)}
            />
          ) : (
            <div className="URL squeeze">URL: {mainLogo}</div>
          )}
        </div>
        <div className={editMode ? "logoCap" : "dataLabel"}>
          <span>PDF Logo</span>
          <div className="logoItem" style={{ backgroundColor: "#1c4479" }}>
            <img src={pdfLogo} />
          </div>
          {editMode === false ? (
            <textarea
              name="PDF"
              value={pdfLogo}
              onChange={(e) => onEditText(e)}
            />
          ) : (
            <div className="URL squeeze">URL: {pdfLogo}</div>
          )}
          {/* <div className="URL squeeze">URL: {pdf}</div> */}
        </div>

        <div className={editMode ? "logoCap" : "dataLabel"}>
          <span>Wesite Mini Icon (favIcon)</span>
          <div className="logoItem">
            <img src={favIcon} />
          </div>
          {editMode === false ? (
            <textarea
              name="favIcon"
              value={favIcon}
              onChange={(e) => onEditText(e)}
            />
          ) : (
            <div className="URL squeeze">URL: {favIcon}</div>
          )}
          {/* <div className="URL squeeze">URL: {favIcon}</div> */}
        </div>
      </div>
    );
  }

  if (item === undefined)
    return (
      <DivButton onClick={() => props.handleAdd(newItem)}>
        Add New Whitelabel Company
      </DivButton>
    );
  // if (item.CompanyName === "") return <AddButton />;

  if (!open) {
    return (
      <div className="WLContainer">
        <h3>{companyName}</h3>
        <DivButton
          onClick={() => {
            setOpen(!open);
            // console.log("WLO :>> ", WLO);
          }}
        >
          Open{" "}
        </DivButton>
      </div>
    );
  } else if (open && !edit && isNew === false) {
    return (
      <div className="WLContainer">
        <h3>{companyName}</h3>
        <div className="dataLabel">
          Domain: <div className="URL">{item.Domain} </div>
        </div>

        {/* <div className="dataLabel">
          Colors:
          <div className="colorContainer">
            {Object.keys(item.Colors).map((key) => {
              let s = key;
              s = s.replace(/([A-Z])/g, " $1").trim();
              return (
                <div className="colorItem dataLabel">
                  <span>{s}: </span>
                  <div
                    className="color"
                    style={{ backgroundColor: item.Colors[key] }}
                  ></div>
                  <span>{item.Colors[key]}</span>
                </div>
              );
            })}
          </div>
        </div> */}

        <Logo />
        <div className="WLbuttons">
          <DivButton onClick={() => setEdit(!edit)}>
            {`Edit ${companyName}`}
          </DivButton>

          <DivButton onClick={() => setOpen(!open)}>Close</DivButton>
        </div>
      </div>
    );
  } else {
    // console.log("domainText :>> ", domainText);
    return (
      <div className="WLContainer">
        <h3>Editing {item.CompanyName}</h3>
        {item.CompanyName === "New WhiteLabel" ? (
          <div className="dataLabel">
            Company Name
            <textarea
              id="CompanyName"
              name="CompanyName"
              value={companyName}
              onChange={(e) => onEditText(e)}
            />
          </div>
        ) : (
          ""
        )}
        <div className="dataLabel">
          Domain
          <textarea
            id="Domain"
            name="Domain"
            value={domainText}
            onChange={(e) => onEditText(e)}
          />
        </div>
        <Logo edit={true} />
        <div>{errorMessage}</div>
        <div className="WLbuttons">
          <DivButton
            onClick={() => {
              setEdit(!edit);
              setDomainText(item.Domain);
            }}
          >
            Cancel Edit
          </DivButton>
          <DivButton onClick={handleSubmit}>Submit</DivButton>
          <DivButton
            onClick={() => {
              setOpen(!open);
              setEdit(!edit);
              setDomainText(item.Domain);
            }}
          >
            Close
          </DivButton>
        </div>
      </div>
    );
  }
}
