import React, { useEffect, useState } from "react";
import { Amplify } from "aws-amplify";
import { Authenticator, withAuthenticator } from "@aws-amplify/ui-react";
// import { Auth } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsconfig from "./aws-exports";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { MenuButton } from "./Components/Inputs/MenuButton";
import { SideMenu } from "./Components/SideMenu";
import { Welcome } from "./Pages/Welcome";
import { UserStats } from "./Pages/UserStats";
import { ConstantValues } from "./Pages/ConstantValues";
import { EditQuestions } from "./Pages/EditQuestions";
import { SmartContracts } from "./Pages/SmartContracts";
import { WhiteLabels } from "./Pages/WhiteLabels";
import { ManageAccounts } from "./Pages/ManageAccounts";
// import SignIn from "./Components/SignIn";
// import AWS from "aws-sdk";

const Requests = require("./Requests.js");

// const sts = new AWS.STS();

// const getAccountId = async () => {
//   try {
//     const response = await sts.getCallerIdentity().promise();
//     const accountId = response.Account;

//     // Use the account ID in your application
//     console.log(accountId);
//   } catch (error) {
//     console.error("Error retrieving account ID:", error);
//   }
// };

// getAccountId();
Amplify.configure(awsconfig);
function App({ signOut, user }) {
  console.log("start");
  // getAccountId();
  // Requests.getAuthConfig().then((cfg) => {
  //   console.log("cfg :>> ", cfg);
  //   // Amplify.configure(cfg);
  //   // Auth.configure(cfg);
  // });
  const [menuState, setMenuState] = useState(false);
  const [dev, setDev] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(true);
  const [data, setData] = useState({});
  let UserName = "Admin";
  // let reload = false;
  useEffect(() => {
    // checkLogin();
    Requests.getAllItems((res) => {
      // console.log("res :>> ", res);
      if (res !== undefined) {
        setData(JSON.parse(res.body));
        // console.log("data :>> ", Object.keys(data));
        setLoading(false);
        // console.log("res :>> ", res.body);
      }
    });
  }, [loading]);

  const toggleMenu = () => setMenuState(!menuState);

  // const checkLogin = () => {
  //   Auth.currentAuthenticatedUser()
  //     .then((sess) => {
  //       console.log("logged in");
  //       setLoggedIn(true);
  //     })
  //     .catch(() => {
  //       console.log("not loggedin");
  //       setLoggedIn(false);
  //     });
  // };

  // const signOut = async () => {
  //   try {
  //     // await Auth.signOut();
  //     // setLoggedIn(false);
  //   } catch (err) {
  //     console.log("Error during sign out: ", err);
  //   }
  // };

  // if (loggedIn === false) {
  //   return (
  //     <div>
  //       <div className="mainContainer">
  //         <div className="App">
  //           <div className="containter card hover">
  //             <div className="content column">
  //               <h1>Sign in</h1>
  //               <h2> Please log in</h2>
  //               <SignIn
  //                 OnSignIn={(user) => {
  //                   UserName = user;
  //                   console.log("user :>> ", user);
  //                   checkLogin();
  //                 }}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
  if (loading)
    return (
      <div>
        <div className="mainContainer">
          <div className="App">
            <div className="containter card hover">
              <div className="content column">
                <h1>Loading Data</h1>
                <h2> please wait</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div>
      <div className="App">
        <Authenticator>
          {({ signOut, user }) => (
            <div className="containter card hover">
              <MenuButton onClick={toggleMenu} />
              <Router>
                <div className={menuState === false ? "menu" : "menu active"}>
                  <SideMenu onClick={toggleMenu} signOut={signOut} />
                </div>
                <div className="content">
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <div className="column">
                          <Welcome user={user} signOut={signOut} />
                        </div>
                      }
                    />
                    <Route
                      path="/CV"
                      element={
                        <ConstantValues data={data} UserName={UserName} />
                      }
                    />

                    <Route
                      path="/Stats"
                      element={<UserStats data={data} UserName={UserName} />}
                    />
                    <Route
                      path="/Contracts"
                      element={
                        <SmartContracts data={data} UserName={UserName} />
                      }
                    />
                    <Route
                      path="/Questions"
                      element={
                        <EditQuestions data={data} UserName={UserName} />
                      }
                    />
                    <Route
                      path="/WhiteLabels"
                      element={<WhiteLabels data={data} UserName={UserName} />}
                    />
                    <Route path="/Accounts" element={<ManageAccounts />} />
                    <Route path="" element={<></>} />
                  </Routes>
                </div>
              </Router>
            </div>
          )}
        </Authenticator>
      </div>
    </div>
  );
}

export default withAuthenticator(App);
