import React, { useState } from "react";
import "../Pages/ConstantValues.css";
import DivButton from "../Components/Inputs/DivButton";
import { PrevValueDropDown } from "../Components/Inputs/PrevValueDropDown";
import { TextInput } from "../Components/Inputs/TextInput";
import { TableEditor } from "../Components/TableEditor";

const Requests = require("../Requests.js");

export function ConstantValues(props) {
  const CVdata = props.data.cvs;
  const CVs = CVdata.sort(
    (a, b) =>
      parseInt(a.CVID.replaceAll("CV", "")) -
      parseInt(b.CVID.replaceAll("CV", ""))
  );
  // console.log("CVs :>> ", CVs);
  const initValues = {};
  const initInputs = {};
  for (let i = 0; i < CVs.length; i++) {
    const e = CVs[i].CVID;
    const item = CVs[i];
    initValues[e] = item.Current.Value;

    initInputs["i" + e] = "";
    initInputs[e] = "";
  }
  const [inputs, setInputs] = useState(initInputs);
  const [values, setValues] = useState(initValues);
  // console.log("values :>> ", values);
  const Tables = props.data.tables;
  const changelog = props.data.changelog;

  function setSpecificInput(name, value) {
    if (inputs[name] === undefined) {
      inputs[name] = {};
      //   console.log(name, inputs[name].val);
    }
    if (inputs[name].val !== value || inputs[name].val === undefined) {
      // if (logging === true) {
      //   console.log(`N:${name} V: ${value}`);
      // }
      setInputs((values) => ({
        ...values,
        [name]: value,
      }));
    }
  }

  function setSpecificValue(name, value) {
    setValues((values) => ({
      ...values,
      [name]: value,
    }));
  }

  function handleSave(id) {
    const o = values[id];
    const n = inputs["i" + id];
    setSpecificValue(id, n);
    Requests.updateCV(id, o, n, props.UserName, () => {
      console.log(id, `old: ${o}`, `new ${n}`, `by ${props.UserName}`);
      console.log("Success");
    });
    // setSpecificInput;
    // Requests.
  }

  function handleSelect(e, id, type) {
    const value = e.target.value;
    setSpecificInput(id, value);
    if (e.target.value !== "") {
      setSpecificInput(`i${id}`, value);
    }
  }
  function handleInputBlur(e, id) {}
  function handleInputChange(e, id, type) {
    let value = e.target.value.replaceAll(",", "");
    setSpecificInput(id, value); //reset the selector
    setSpecificInput(id.replaceAll("i", ""), ""); //reset the input
  }
  function validateInput(id, type) {
    const IV = inputs[`i${id}`];
    if (isNaN(IV) || IV == "") return false;
    if (type === "percent") {
      if (IV > 100) return false;
    } else {
      if (IV < 1) return false;
    }
    if (IV === values[id]) return false;
    return true;
  }

  return (
    <>
      <div className="column">
        <h1>Edit Constant Values</h1>
        <table className="head">
          <thead>
            <tr>
              <th className="ID">ID</th>
              <th className="description">Description</th>
              <th className="value">Value</th>
              <th className="dateSet">Date Set</th>

              <th className="edit">Edit</th>
              <th className="previous">Previous Values</th>
            </tr>
          </thead>
        </table>
        <div className="column editArea scrollBox">
          <table>
            <thead>
              <tr>
                <th className="ID">ID</th>
                <th className="description">Description</th>
                <th className="value">Value</th>
                <th className="dateSet">Date Set</th>
                <th className="edit">Edit</th>
                <th className="previous">Previous Values</th>
              </tr>
            </thead>
            <tbody>
              {CVs.map((item) => {
                const key = item.CVID;
                const dateint = parseInt(item.Current.SetDate);
                const date = new Date(dateint);
                const value = values[key];
                const dateDisplay =
                  dateint > 1685682000000 ? date.toDateString() : "original";
                return (
                  <tr key={key}>
                    <td className="ID">{key}</td>
                    <td className="description">{item.Description}</td>
                    <td className="value">
                      {item.Type === "percent"
                        ? `${(parseFloat(value) * 100).toFixed(2)}%`
                        : item.Type === "dollar"
                        ? `$${parseInt(value).toLocaleString()}`
                        : value}
                    </td>
                    <td className="dateSet">{dateDisplay}</td>
                    <td className="edit">
                      <div>
                        <TextInput
                          id={`i${item.CVID}`}
                          type={item.Type}
                          value={inputs[`i${item.CVID}`]}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                        />
                        <DivButton
                          classList={
                            validateInput(item.CVID, item.Type) === false
                              ? "disabled"
                              : ""
                          }
                          onClick={() => handleSave(item.CVID)}
                        >
                          Save
                        </DivButton>
                      </div>
                    </td>
                    <td className="previous">
                      <PrevValueDropDown
                        ID={item.CVID}
                        type={item.Type}
                        value={inputs[item.CVID]}
                        data={changelog}
                        onChange={handleSelect}
                      />
                      {/* <DivButton>Set & Save</DivButton> */}
                    </td>
                  </tr>
                  // <div key={item.ID} className="lineItem">
                  //   <div>ID: {item.ID}</div>
                  //   <div>Description: {item.Description}</div>
                  //   <div>Value: {item.Current.Value}</div>
                  //   <div>Set on: {item.SetDate}</div>
                  //   <div></div>
                  // </div>
                );
              })}
            </tbody>
          </table>
        </div>
        <div>
          <h2>Tables</h2>
        </div>
        <div className="CVbuttons">
          {Tables.map((t) => {
            return (
              <TableEditor
                key={t.TableID}
                data={t}
                UserName={props.UserName}
                // onClick={() => {
                //   console.log(t.TableID);
                // }}
              >
                {t.Name}
              </TableEditor>
            );
          })}
          {/* <DivButton>Edit Mortgage Rate Table</DivButton>
          <DivButton>Edit Median Home Health Care Expenses per Table</DivButton>
          <DivButton>Edit Standard Deductions Table</DivButton>
          <DivButton>Edit Provisional Income Table</DivButton>
          <DivButton>Edit Actuarial Life Table</DivButton>
          <DivButton>Edit Net Worth by Age Table</DivButton> */}
          {/* <DivButton>Edit </DivButton> */}
        </div>
      </div>
    </>
  );
}
