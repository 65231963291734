import React from "react";
import "../Inputs/TextInput.css";
export function TextInput(props) {
  return (
    <>
      <div className="inputLine">
        <label className="inputLabel" id={`${props.label}_label`}>
          {props.label === undefined ? "" : props.label}
        </label>
        <input
          id={props.id}
          value={props.value}
          onChange={(e) => props.onChange(e, props.id, props.type)}
          onBlur={(e) => props.onBlur(e, props.id)}
          className="InputBox"
        />
      </div>
    </>
  );
}
