import React, { useState } from "react";
import { WhiteLabelItem } from "../Components/WhiteLabelItem";
// import WL from "../WhiteLabelTest.json";
import "../Pages/WhiteLabel.css";

const Requests = require("../Requests.js");
export function WhiteLabels(props) {
  const WL = props.data.whitelabels;
  // console.log("WL :>> ", WL);
  const [WLLists, setList] = useState(WL);
  function handleSubmit(WLO) {
    //update DB

    Requests.updateWhiteLabel(WLO.ID, "", WLO, props.UserName, () => {
      // update state
      const indexToAdd = WLLists.findIndex((o) => o.ID === WLO.ID);
      if (indexToAdd !== undefined) {
        const tempList = [...WLLists];
        tempList[indexToAdd] = WLO;
        setList(tempList);
      }
      console.log("updated!");
    });
  }

  function handleAdd(item) {
    if (WLLists[WLLists.length - 1].CompanyName !== "New WhiteLabel") {
      const tempList = [...WLLists];
      item.ID = "WL" + WL.length;
      tempList.push(item);
      setList(tempList);
    }
  }
  return (
    <div className="WhiteLabelPage">
      <h1>Manage White Labels</h1>
      <div className="WhiteLabelContainer">
        {WLLists.map((w) => {
          const key = w.CompanyName;
          return (
            <div key={key}>
              <WhiteLabelItem
                WhiteLabelObj={w}
                UserName={props.UserName}
                handleSubmit={handleSubmit}
              />
            </div>
          );
        })}
        <WhiteLabelItem handleAdd={handleAdd} />
      </div>
    </div>
  );
}
