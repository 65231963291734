import React from "react";

export function UserStats(props) {
  const data = props.data.stats;
  const wl = props.data.whitelabels;
  console.log(data);
  console.log("wl :>> ", wl);

  const report = { Total_Reports: 0, Paid_Reports: 0 };
  for (let i = 0; i < data.length; i++) {
    const r = data[i];
    const WLID = wl.find((item) => item.ID === `WL${r.white_label}`);
    const white_label = WLID.CompanyName.replaceAll(" ", "_");

    report.Total_Reports++;
    if (r.paid === 1) report.Paid_Reports++;

    let wlTotal = `${white_label}_Total`;
    if (report[wlTotal] === undefined) {
      report[wlTotal] = 1;
    } else {
      report[wlTotal]++;
    }
    let wlPaid = `${white_label}_Paid`;
    if (report[wlPaid] === undefined) {
      if (r.paid === 1) {
        report[wlPaid] = 1;
      } else {
        report[wlPaid] = 0;
      }
    } else {
      if (r.paid === 1) {
        report[wlPaid]++;
      }
    }
  }
  return (
    <div className="column">
      <h1>View User Stats</h1>
      <div className="column sub">
        {Object.keys(report).map((k) => {
          return (
            <div className="row" key={k}>
              <div> {k.replaceAll("_", " ")}</div>
              <div> {report[k]}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
