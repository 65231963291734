import React, { useState } from "react";
import { GrayTransparentPopup } from "./GrayTransparentPopup";
import "./TableEditor.css";

const Requests = require("../Requests.js");
export function TableEditor(props) {
  const data = props.data;
  const ID = data.TableID;
  const TName = data.Name;
  const TRows = data.dataRows;
  const THeader = data.colData;
  const TOptions = data.Options;
  const [tableData, setTableData] = useState(TRows);
  // console.log(ID, "THeader :>> ", THeader);
  // console.log(TName + "TRows :>> ", TRows);
  // console.log("Object.keys(data) :>> ", Object.keys(data));
  const colCalc = parseFloat((1.0 / THeader.length) * 93);
  const colWidth = colCalc.toFixed(2) + "%";
  const authColWidth = ((colCalc / 3) * 2).toFixed(2) + "%";
  const quoteColWidth = (colCalc + colCalc / 3).toFixed(2) + "%";

  function setSpecificInput(row, name, value) {
    const newTRows = [...tableData];
    newTRows[row][name] = value;
    setTableData(newTRows);
  }

  function handleAdd() {
    const newTRows = [...tableData];
    const TRowsSize = tableData.length - 1;
    if (newTRows[TRowsSize][THeader[0].header] !== "") {
      const newRow = {};
      for (let i = 0; i < THeader.length; i++) {
        const h = THeader[i].header;
        newRow[h] = "";
      }
      newTRows.push(newRow);
      setTableData(newTRows);
    }
  }
  function handleDelete(index) {
    const value = index;
    // console.log("value :>> ", value);
    const newTRows = tableData.filter((i) => {
      return i !== value;
    });
    // console.log("newTRows :>> ", newTRows);
    setTableData(newTRows);
  }

  function handleChange(e, item) {
    const id = e.target.id.split("_");
    const row = id[0] - 1;
    const name = id[1];
    const value = e.target.value;
    console.log("row :>> ", row, "name :>> ", name, "value :>> ", value);
    setSpecificInput(row, name, value);
  }

  function handleSubmit() {
    console.log("ID :>> ", ID);
    const o = data;
    const n = tableData;
    console.log(ID, o, n, props.UserName);
    Requests.updateTable(ID, o, n, props.UserName, () => {
      console.log("Finished");
    });
  }

  let rowCount = 0;
  return (
    <GrayTransparentPopup label={props.children} onAccept={handleSubmit}>
      <h2>Edit {TName}</h2>
      <div className="editTableRow">
        <div className="editTableCell editTableHeader" style={{ width: "7%" }}>
          Row
        </div>
        {THeader.map((h) => {
          return (
            <div
              key={h.header}
              className="editTableCell editTableHeader"
              style={
                h === "author"
                  ? { width: authColWidth }
                  : h === "quote"
                  ? { width: quoteColWidth }
                  : { width: colWidth }
              }
            >
              {h.display}
            </div>
          );
        })}
      </div>
      <div className="editTable">
        {tableData.map((r) => {
          //   console.log("r :>> ", r);
          rowCount++;
          let colCount = 0;
          // console.log("rowCount :>> ", rowCount);
          return (
            <div key={rowCount} className="editTableRow">
              <div className="editTableCell" style={{ width: "7%" }}>
                {TOptions.CanDelete === true ? (
                  <div
                    className="TableFuncButton"
                    onClick={() => handleDelete(r)}
                  >
                    {"X"}
                  </div>
                ) : (
                  ""
                )}
                <div> {rowCount}</div>
              </div>
              {THeader.map((c) => {
                const cell = c.header;
                const colName = cell;
                colCount++;
                let cellText = r[cell];
                return (
                  <div
                    key={colCount}
                    className="editTableCell"
                    style={
                      colName === "author"
                        ? { width: authColWidth }
                        : colName === "quote"
                        ? { width: quoteColWidth }
                        : { width: colWidth }
                    }
                  >
                    {THeader[colCount - 1].type === "Static" ? (
                      <div>{cellText}</div>
                    ) : (
                      <textarea
                        id={rowCount + "_" + colName}
                        value={cellText}
                        className={TName.replaceAll(" ", "")}
                        onChange={(e) => handleChange(e, r)}
                      ></textarea>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
        {TOptions.CanAdd === true ? (
          <div className="editTableRow Add">
            <div className="TableFuncButton" onClick={handleAdd}>
              +
            </div>
            <div onClick={handleAdd}> Add Row</div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="foot"> RowCount: {TRows.length} </div>
      {/* <div className="foot"> RowCount: {TRows.length} </div> */}
      {/* {JSON.stringify(data)} */}
    </GrayTransparentPopup>
  );
}
