import React, { useState } from "react";
import "../Inputs/MenuButton.css";
export function MenuButton(props) {
  // document.getElementById("id").classList.toggle;
  const [active, setActive] = useState(false);

  return (
    <div className="circle">
      <label className={active ? "menubutton active" : "menubutton"}>
        <input
          type="checkbox"
          onClick={() => {
            setActive(!active);
            props.onClick();
          }}
        />
      </label>
    </div>
  );
}
