// const AWS = require("aws-sdk");
// AWS.config.update({ region: "us-east-1" });
// const ssm = new AWS.SSM();
const DBURL =
  "https://srwwsn660e.execute-api.us-east-1.amazonaws.com/staging/items/";

// const params = {
//   Name: "DASH_AUTH",
//   WithDecryption: true, // Specify true if the parameter is a SecureString
// };

// export async function getAuthConfig() {
//   try {
//     const data = await ssm.getParameter(params).promise();
//     const parameterValue = data.Parameter.Value;
//     return parameterValue;
//   } catch (error) {
//     console.log(error);
//     throw error;
//   }
// }

export async function getAllItems(callback) {
  getItem("all", callback);
}

export function getItem(table, callback) {
  fetch(DBURL + table)
    .then((res) => res.json())
    .then(callback)
    .catch((err) => {
      console.log(err);
    });
}

function editItem(table, data, callback) {
  fetch(DBURL + table, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((r) => {
      console.log("r :>> ", r);
      callback();
    })
    .catch((err) => {
      console.log(err);
    });
}

function addItem(table, data, callback) {
  fetch(DBURL + table, {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then(callback)
    .catch((err) => {
      console.log(err);
    });
}

export function updateCV(ID, oldItem, newItem, user, callback) {
  const now = new Date();
  const timestamp = now.getTime();
  const data = {
    ID: ID,
    ChangedBy: user,
    PrevValue: oldItem,
    NewValue: { Value: newItem, SetDate: timestamp },
    timestamp: timestamp,
  };
  console.log("data :>> ", data);
  editItem("constantvalue", data, callback);
}

export function updateQDBItem(QID, oldItem, newItem, user, callback) {
  const now = new Date();
  const timestamp = now.getTime();
  const data = {
    ID: QID,
    ChangedBy: user,
    PrevValue: oldItem,
    NewValue: newItem,
    timestamp: timestamp,
  };
  console.log("data :>> ", data);
  editItem("qdb", data, callback);
}

export function updateSCItem(ID, oldItem, newItem, user, callback) {
  const now = new Date();
  const timestamp = now.getTime();
  const data = {
    ID: ID,
    ChangedBy: user,
    PrevValue: oldItem,
    NewValue: newItem,
    timestamp: timestamp,
  };
  console.log("data :>> ", data);
  editItem("smart", data, callback);
}

export function updateTable(ID, oldItem, newItem, user, callback) {
  const now = new Date();
  const timestamp = now.getTime();
  const data = {
    ID: ID,
    ChangedBy: user,
    PrevValue: oldItem,
    NewValue: newItem,
    timestamp: timestamp,
  };
  console.log("data :>> ", data);
  editItem("cvtable", data, callback);
}

export function updateWhiteLabel(WLID, oldItem, newItem, user, callback) {
  const now = new Date();
  const timestamp = now.getTime();
  const data = {
    ID: WLID,
    ChangedBy: user,
    PrevValue: oldItem,
    NewValue: newItem,
    timestamp: timestamp,
  };
  console.log("data :>> ", data);
  editItem("whitelabel", data, callback);
}
