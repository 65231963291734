import React, { useState } from "react";
import "./EditQuestions.css";
import { TextInput } from "../Components/Inputs/TextInput";
import { GrayTransparentPopup } from "../Components/GrayTransparentPopup";
import { PrevValueDropDown } from "../Components/Inputs/PrevValueDropDown";

const Requests = require("../Requests.js");

export function EditQuestions(props) {
  const data = props.data.qdb;
  const changelog = props.data.changelog;
  const qdb = data.sort((a, b) => parseInt(a.Order) - parseInt(b.Order));

  // const qInit ={}
  // for (let i = 0; i < qdb.length; i++) {
  //   const q = qdb[i];
  //   qInit[q.]
  // }
  const [questions, setQuestions] = useState(qdb);
  const [currQuestionText, setQuestionText] = useState("");
  const [currGuideText, setGuideText] = useState("");
  const [currSelect, setSelect] = useState("");
  // console.log("questions :>> ", questions);
  function handleSelect(event, QID) {
    const timestamp = event.target.value;
    if (timestamp !== "") {
      const changeObj = changelog[QID].ChangeHistory[timestamp].NewValue;
      setQuestionText(changeObj.QuestionText);
      setGuideText(changeObj.GuideText);
    } else {
      setQuestionText("");
      setGuideText("");
    }
    setSelect(timestamp);
  }
  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.name === "Question") setQuestionText(value);
    else setGuideText(value);
    setSelect("");
  }

  function handleSubmit(q) {
    const Old = { ...q };
    const New = { ...q };
    // Requests.updateQDBItem(q.QID, "both", Old, New);
    New.QuestionText = currQuestionText;
    New.GuideText = currGuideText;
    // console.log("qindex :>> ", qindex);
    Requests.updateQDBItem(q.QID, Old, New, props.UserName, () => {
      //   qdb[qindex].QuestionText = currQuestionText;
      //   qdb[qindex].GuideText = currGuideText;

      setSpecificQuestion(q.QID);
      console.log("done!");
    });
    // console.log();
  }

  function setSpecificQuestion(i) {
    const newQuestions = [...questions];
    const changedQuest = newQuestions.find((q) => q.QID === i);
    newQuestions[newQuestions.indexOf(changedQuest)].QuestionText =
      currQuestionText;
    newQuestions[newQuestions.indexOf(changedQuest)].GuideText = currGuideText;
    setQuestions(newQuestions);
  }

  function onCancel() {
    console.log("cancel");
    setGuideText("");
    setQuestionText("");
    setSelect("");
  }
  function onEditButtonClick(q) {
    setQuestionText(q.QuestionText);
    setGuideText(q.GuideText);
  }

  return (
    <div className="column">
      <h1>Edit Questions</h1>

      <div className="questions column border qTable">
        <div className="header qTable qTableRow">
          <div className="qTableItem shortItem">QID</div>
          <div className="qTableItem longItem">Question Text Text</div>
          <div className="qTableItem longItem">Guide Text</div>
          <div className="qTableItem shortItem">Page</div>
        </div>
        <div className="scrollBox column">
          {questions.map((q) => {
            return (
              <div key={q.QID} className="column qTableRow">
                <div className="qTableRow">
                  <div className="qTableItem shortItem">{q.QID}</div>
                  <div className="qTableItem longItem">{q.QuestionText}</div>
                  <div className="qTableItem longItem">{q.GuideText}</div>
                  {/* <div className="qTableItem longItem">{q.page}</div> */}
                  <div className="qTableItem shortItem">
                    <GrayTransparentPopup
                      onOpen={() => onEditButtonClick(q)}
                      onCancel={onCancel}
                      onAccept={() => handleSubmit(q)}
                      label="Edit"
                    >
                      <h2>Edit Question {q.QID}</h2>
                      <div>
                        <span>Question Text</span>
                        <textarea
                          name="Question"
                          value={currQuestionText}
                          onChange={(event) => handleChange(event)}
                        ></textarea>
                      </div>
                      <div>
                        <span>Guide Text</span>
                        <textarea
                          name="Guide"
                          onChange={(event) => handleChange(event)}
                          value={currGuideText}
                        ></textarea>
                      </div>
                      <div>
                        <span>Select Previous Data</span>
                        {changelog[q.QID] === undefined ? (
                          <div className="popUpSmall">
                            --No previous data found--
                          </div>
                        ) : (
                          <PrevValueDropDown
                            ID={q.QID}
                            type={q.QID}
                            value={currSelect}
                            data={changelog}
                            onChange={handleSelect}
                            DateOnly={true}
                          ></PrevValueDropDown>
                        )}
                      </div>
                      <div className="popUpSmall">
                        changes may not appear on table until page is reloaded.
                      </div>
                    </GrayTransparentPopup>
                  </div>
                </div>
                {/* <div className="qTableRow">
                  <div className="qTableItem shortItem">Edit:</div>
                  <div className="qTableItem longItem">{q.QuestionText}</div>
                  <div className="qTableItem longItem">{q.GuideText}</div>
                  <div className="qTableItem longItem">{q.page}</div>
                </div> */}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
