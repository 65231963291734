import React, { useState } from "react";
import DivButton from "./Inputs/DivButton";
import "./Popup.css";
export function GrayTransparentPopup(props) {
  const [open, setOpen] = useState(false);

  function onOpen() {
    setOpen(true);
    if (props.onOpen !== undefined) props.onOpen();
  }

  function onAccept() {
    if (props.onAccept !== undefined) {
      props.onAccept();
      setOpen(false);
    } else setOpen(false);
  }

  function onCancel() {
    setOpen(false);
    if (props.onCancel !== undefined) props.onCancel();
  }

  if (open)
    return (
      <div className="popupOverlay">
        <div className="popupContianer">
          <div className="popupContent">{props.children}</div>
          <div className="popupNav">
            <DivButton className="popupSubmit" onClick={onAccept}>
              Submit
            </DivButton>
            <DivButton className="popupCancel" onClick={onCancel}>
              Cancel
            </DivButton>
          </div>
        </div>
      </div>
    );
  else {
    return <DivButton onClick={onOpen}>{props.label}</DivButton>;
  }
}
