import React from "react";

export function ManageAccounts(props) {
  return (
    <>
      <h1>Manage Accounts</h1>
      <p>Test</p>
    </>
  );
}
