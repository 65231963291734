import React from "react";
export default function DivButton(props) {
  const classList =
    props.classList === undefined
      ? "divButton"
      : "divButton " + props.classList;

  return (
    <>
      <div className={classList} onClick={props.onClick}>
        {props.children}
      </div>
    </>
  );
}
