import React, { useState } from "react";

export function PrevValueDropDown(props) {
  const ID = props.ID;
  const dataItem = props.data[ID];
  const options = dataItem === undefined ? "" : props.data[ID].ChangeHistory;
  const type = props.type;
  //   console.log("ID :>> ", ID);
  //   console.log("options :>> ", options);

  if (options === "") return <>No Data</>;
  return (
    <div>
      <select
        value={props.value}
        onChange={(event) => {
          props.onChange(event, ID);
        }}
      >
        <option value=""></option>
        {Object.keys(options).map((o) => {
          const item = options[o];
          const d = new Date(parseInt(o));
          let val = item.NewValue;
          const changer = item.ChangedBy;
          let optionText;
          // console.log("o :>> ", o);
          if (props.DateOnly === undefined || props.DateOnly === false) {
            if (type === "percent") {
              val = (val * 100).toFixed(2);
            }
            optionText = `${val} :${changer}- ${d.toDateString()}`;
            //   console.log("new Date(o) :>> ", new Date(o).toDateString());
          } else {
            val = o;
            optionText = `${changer}- ${d.toDateString()}`;
          }
          return (
            <option key={o} value={val}>
              {optionText}
            </option>
          );
        })}
      </select>
    </div>
  );
}
