import React, { useState } from "react";
import "./SmartContracts.css";
import DivButton from "../Components/Inputs/DivButton";
import { TextInput } from "../Components/Inputs/TextInput";
import { GrayTransparentPopup } from "../Components/GrayTransparentPopup";
import { PrevValueDropDown } from "../Components/Inputs/PrevValueDropDown";
const Requests = require("../Requests");
export function SmartContracts(props) {
  const data = props.data.scs;
  const changelog = props.data.changelog;
  const scs = data.sort((a, b) => parseInt(a.ID) - parseInt(b.ID));

  const [SClist, setSC] = useState(scs);
  const [currText, setText] = useState("");
  const [currSelect, setSelect] = useState("");
  // console.log("Object.keys(changelog) :>> ", Object.keys(changelog));

  function handleSelect(e, id) {
    const value = e.target.value;
    setSelect(value);
    if (value !== "") {
      setText(changelog[id].ChangeHistory[value].NewValue);
    } else {
      setText("");
    }
  }

  function setSpecificSC(i) {
    const newSCList = [...SClist];
    const changedSC = newSCList.find((sc) => sc.ID === i);
    newSCList[newSCList.indexOf(changedSC)].Description = currText;
    setSC(newSCList);
  }

  function handleChange(e) {
    const value = e.target.value;
    setText(value);
    setSelect("");
  }

  function handleSubmit(s) {
    // console.log("s :>> ", s);
    const ID = s.ID;
    const n = currText;
    const o = s.Description;
    console.log("ID :>> ", ID, "old :>> ", o, "new :>> ", n);
    setSpecificSC(s.ID);
    Requests.updateSCItem(ID, o, n, props.UserName, () => {
      setSpecificSC(s.ID);
      console.log("done!");
    });
  }

  function onCancel() {
    console.log("cancel");
    setSelect("");
    setText("");
  }
  function onEditButtonClick(c) {
    setText(c.Description);
    // console.log(`changelog[${c.ID}] :>>`, changelog[c.ID]);
  }

  return (
    <div className="column">
      <h1>Edit Smart Contracts</h1>
      <div className="SCTable column">
        <div className="SCRow SCHeader">
          <div className="SCShort">ID </div>
          <div className="SCMid">Summary </div>
          <div className="SCLong">Description </div>
        </div>
        <div className="column scrollBox">
          {SClist.map((c) => {
            const ID = c.ID;
            return (
              <div key={ID} className="SCRow">
                <div className="SCShort">{c.ID} </div>
                <div className="SCMid">{c.Summary} </div>
                <div className="SCLong column">
                  <div>{c.Description}</div>
                  <GrayTransparentPopup
                    onOpen={() => onEditButtonClick(c)}
                    onCancel={onCancel}
                    onAccept={() => handleSubmit(c)}
                    label="Edit"
                  >
                    <h2>Edit ActionStep {ID}</h2>
                    <div>
                      <span> Description Text</span>
                      <textarea
                        name="Text"
                        onChange={(event) => handleChange(event)}
                        value={currText}
                      ></textarea>
                    </div>
                    <div>
                      <span>Select Previous Data</span>
                      {changelog[ID] === undefined ? (
                        <div className="popUpSmall">
                          --No previous data found--
                        </div>
                      ) : (
                        <PrevValueDropDown
                          ID={c.ID}
                          type={c.ID}
                          value={currSelect}
                          data={changelog}
                          onChange={handleSelect}
                          DateOnly={true}
                        ></PrevValueDropDown>
                      )}
                    </div>
                    <div className="popUpSmall">
                      changes may not appear on table until page is reloaded.
                    </div>
                  </GrayTransparentPopup>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
